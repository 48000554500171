import React, {useState} from 'react'
import {graphql, Link} from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Avatar from '../images/avatar-placeholder.png'
import SEO from '../components/seo'
import axios from "axios";
import queryString from "query-string";

const config = require('../config/default');


function PoliticalParty() {

    const search = typeof window !== `undefined` ? location.search : {q: '/none/'}
    let params = queryString.parse(search)

    let partyId = params.id
    // console.log("partyId", partyId)

    const [state, setState] = useState({
        party: {
            "name": "",
            "image": "",
            "partiesOrganizationids": [],
        },
        partyId: partyId,
        loading: false,
        is_search: true
    });

    let FIND_PARTY = function (search) {
        // console.log("FIND_PARTY")


        if (search === null) return []

        let query = `
query($uid: String) {
  allParties(id: $uid) {
    edges {
      node {
        id
        name
        image
        partiesOrganizationids {
          memberid {
            id
            name
            image
          }
          role
          
        }
      }
    }
  }
}
          `.replace(/\n/g, ' ');

        axios.post(config.GRAPHQL_API_URL, {
            query: query,
            variables: {
                "uid": state.partyId
            }
        })
            .then(function (response) {
                if (response.data.data.allParties.edges.length > 0) {
                    let party = response.data.data.allParties.edges[0].node;
                    // console.log(party)

                    setState({
                        "is_search": false,
                        "loading": false,
                        "partyId": state.partyId,
                        "party": party
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        setState({
            "is_search": false,
            "loading": false,
            "partyId": state.partyId,
            "party": {
                "name": "",
                "image": "",
                "partiesOrganizationids": [],
            }
        });


    }

    if (state.is_search) FIND_PARTY(partyId);
    const party = state.party

    const getRoleName = ({role}) => {
        // console.log("role", role)
        return {
            leader: 'หัวหน้าพรรค',
            mp: 'ส.ส.',
            "ex-mp": 'อดีต ส.ส.',
            senator: 'ส.ว.',
            legislator: 'สภานิติบัญญัติ'
        }[role]
    }

    let avatarImage = Avatar
    if (party.image && party.image !== "") {
        avatarImage = party.image
    }

    const headerBlock = () => (<div className="flex flex-col sm:flex-row max-w-6xl mx-auto px-4 md:px-8 py-2 md:py-4">
        <div className="relative text-center sm:w-24">
            <img src={avatarImage} alt="avatar image"
                 className="inline-block shadow-xl w-20 h-20  sm:w-24 sm:h-24 sm:absolute sm:top-0 sm:left-0"/>
        </div>
        <h1 className="ml-0 sm:ml-8 text-white text-center sm:text-left text-xl sm:text-2xl md:text-4xl">{party.name}</h1>
    </div>)

    const getPartyName = (party) => {
        if (party === 'Parliament') {
            return "วุฒิสภา"
        }
        return party;
    }

    return (
        <Layout >
            <SEO
                keywords={[`political`, `party`]}
                title={getPartyName(party.name)}
            />
            <div className="bg-main p-4 sm:p-8 block">
                <div className="block">
                    <div className="">
                        <div className="flex">
                            <img src={avatarImage} alt="avatar image"
                                 className="inline-block rounded-full shadow-xl w-20 h-20  sm:w-24 sm:h-24 object-cover"/>
                            <h1 className="ml-5 mt-5 text-black text-center sm:text-left text-xl sm:text-2xl md:text-4xl">{getPartyName(party.name)}</h1>

                        </div>
                    </div>
                </div>

                <div className="block mt-5">
                    <h3 className="text-2xl font-bold">บุคคลที่เกี่ยวข้อง</h3>
                    <div className="grid md:grid-cols-3 gap-4 mt-4 sm:mt-8">
                        {party.partiesOrganizationids.map(it => (
                            <Link className="flex hover:shadow bg-white hover:bg-orange-300 p-4 rounded border"
                                  key={it.memberid.id}
                                  to={`/person?id=${it.memberid.id}`}>
                                {it.memberid.image? <img src={it.memberid.image} alt="avatar image" className="rounded-full w-8 h-8 object-cover"/>:
                                    <img src={Avatar} alt="avatar image" className="rounded-full w-8 h-8"/>
                                }
                                <div className="ml-4">
                                    <div>{it.memberid.name}</div>
                                    <div className="text-sm hidden">{getRoleName(it)}</div>
                                </div>

                            </Link>
                        ))}
                    </div>
                </div>

            </div>
        </Layout>
    )
}


export default PoliticalParty

